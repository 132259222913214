let API_URL = null;
let REACT_APP_ENV = null;
let DOMAIN = null;

REACT_APP_ENV = process.env.REACT_APP_MODE;

const SITE_API_URL = "https://rossini-vv.ru/";

if (process.env.REACT_APP_MODE === 'production') {
    API_URL = "https://api.e-xchanger.ru/api/";
    DOMAIN = ".e-xchanger.ru";
} else if (process.env.REACT_APP_MODE === 'staging') {
    API_URL = "https://api.e-xchanger.ru/api/";
    DOMAIN = "localhost";
} else {
    REACT_APP_ENV = "development";
    API_URL = "http://127.0.0.1:8000/api/";
    DOMAIN = "localhost";
}

console.log("App environment is set to " + REACT_APP_ENV);

export { API_URL, REACT_APP_ENV, DOMAIN, SITE_API_URL };
