import Main from './components/routing';
import './App.scss';

function App() {
  return (
      <div className="App">
        <Main />
      </div>
  );
}

export default App;
