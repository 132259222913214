import {Link} from "react-router-dom";

//import MainLayout from "./layout";


const PageNotFound = (props) => {
    const {full} = props;

    const response = (<div className="page">
        <h1 className="page__title">Ошибка 404</h1>
        <div className="page__note">
            Вы попали на несуществующую страницу.<br/>
            Если вы перешли сюда по ссылке, сообщите о ней нашему оператору.
        </div>
        <Link to="/" className="ant-btn ant-btn-primary">Сдалать заказ</Link>
    </div>);

    //return full ? <MainLayout>{response}</MainLayout> : response;
    return response;
};

export default PageNotFound;
