import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component, exact = false, path, authenticated, render }) => (
    <Route
        exact={exact}
        path={path}
        render={props => {
            if (authenticated()) {
                if (component) {
                   return React.createElement(component, props)
                } else if (render) {
                    return render(props);
                }
            } else {
                return <Redirect to={{
                    pathname: '/sign_in'
                }}/>
            }
    }}
    />
);

export default PrivateRoute;
