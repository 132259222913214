import Cookie from "js-cookie";

import {RESPONSE_ERRORS, RESPONSE_SUCCESS} from "../constants/global";
import {DOMAIN} from "../config/config";

export function isLoggedIn() {
    if (getToken()){
        return true;
    }
    return false;
}

export function setToken(token) {
    Cookie.set("token", token, {domain: DOMAIN, expires: 365});
}

export function getToken() {
    return Cookie.get("token") || null;
}

export function removeToken () {
    Cookie.remove("token", {domain: DOMAIN});
}

export function errorMessage(errors) {
    return {
        type: RESPONSE_ERRORS,
        errors
    };
}

export function successMessage(success) {
    return {
        type: RESPONSE_SUCCESS,
        success
    };
}