import {Form, Input, Button, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

//import {userSignIn} from "../../actions/auth";
import {isLoggedIn} from "../../actions/global";
//import {checkInn} from "../../validators/inn";
//import {loadingIcon} from "../utils/svg";

function SignIn(props) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    //const {authFormIsSending, authFormResponse}= useSelector(state => state.auth);
    const authFormIsSending = false;
    const authFormResponse = null;

    const onFinish = values => {
        //dispatch(userSignIn(values));
    };

    if(isLoggedIn()) {
        return (<Redirect to='/' />)
    }

    return (<Form
            colon={false}
            onFinish={onFinish}
            autoComplete="off"
            className="form auth-form"
            form={form}
            validateTrigger="onSubmit"
        >
            <div className="form__logo">Rossini</div>
            <Form.Item
                name="identifier"
                className="form__item"
                rules={[{
                    required: true,
                    message: "Заполните поле ИНН"
                }]}
            >
                <Input placeholder="Введите ИНН" />
            </Form.Item>
            <Form.Item
                name="password"
                className="form__item"
                rules={[{
                    required: true,
                    message: "Заполните поле пароль"
                }]}
            >
                <Input placeholder="Введите пароль"  type="password" />
            </Form.Item>
            <Button type="primary" htmlType="submit" disabled={authFormIsSending}>
                {authFormIsSending ? <Spin />:"Войти" }
            </Button>
            <div className="form__response form__response_error">
                {authFormResponse}
            </div>
        </Form>
    )
}

export default SignIn;
