import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import configureStore, { history } from './store';

import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import ru_RU from 'antd/lib/locale-provider/ru_RU';

import App from './App';
import reportWebVitals from './reportWebVitals';

export const store = configureStore(/* provide initial state if any */);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ConfigProvider locale={ru_RU}>
                    <App />
                </ConfigProvider>
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
