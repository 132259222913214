import { Route, Switch } from "react-router-dom";

import PageNotFound from "./not-found";

import PrivateRoute from "./auth/route";
import {isLoggedIn} from "../actions/global";

import Password from "./auth/password";
import Info from "./info";
import Orders from "./orders";

import SignIn from "./auth/sign_in";
import SignOut from "./auth/sign_out";

// не убирать !!!!!
import axios from "../actions/axios";

export default function Main() {
    return (
    <div className="app__wrapper">
        <Switch>
            <Route path="/sign_in" render={(props) => (
                <SignIn {...props}/>
            )}
            />
            <PrivateRoute path="/sign_out" authenticated={isLoggedIn} render={(props) => (
                <SignOut {...props}/>
            )}
            />
            <PrivateRoute exact path="/" authenticated={isLoggedIn} component={Orders}/>
            <PrivateRoute path="/info" authenticated={isLoggedIn} component={Info} />
            <PrivateRoute exact path="/password" authenticated={isLoggedIn} component={Password}/>

            <Route render={(props) => (
                <PageNotFound {...props} needLayout={true}/>
            )} />
        </Switch>
    </div>
    )
}
