import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";

import {removeToken} from '../../actions/global';
//import {fetchUserSessionSuccess} from "../../actions/users";

function SignOut(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(fetchUserSessionSuccess({}));
    // componentWillUnmount
    return () => {
      removeToken();
    }}, [dispatch]);

  return (
    <Redirect to="/sign_in" />
  );
}

export default SignOut;
