import {Link} from "react-router-dom";

function Info(props) {
    return (
        <div>
            инфо
            <Link to='/'>Заказы</Link>
        </div>);
}

export default Info;